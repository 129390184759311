import { type Composer, type UseI18nOptions } from "vue-i18n"

type StripSuffix<
  T extends string,
  S extends string,
  Strict extends boolean = false,
> = T extends `${infer P}${S}` ? P : Strict extends true ? never : T

type UseI18nUtilsOptions = UseI18nOptions & {
  instance?: Composer
}
export function useI18nUtils(options?: UseI18nUtilsOptions) {
  const user = useUser()
  const display = useDisplay()
  const i18n = options?.instance ?? useI18n(options)

  function tbool(path: string, value: boolean | "true" | "false", params = {}) {
    /**
     * Requires translation path and a value,
     * useful when need to display conditional translations based on a boolean value.
     *
     * translate should be structured this way:
     * path: {
     *  "true": "Yes",
     *  "false": "No"
     * }
     */
    if (typeof value === "boolean" || value === "true" || value === "false") {
      return i18n.t(`${path}.${String(value)}`, params)
    } else {
      return null
    }
  }

  function getFemaleOrMale(translation: string | { female: string; male: string }) {
    const userGender = user.user.value?.gender

    if (typeof translation === "string") {
      return translation
    } else if (!user.isUserExists() || i18n.locale.value === "en") {
      return translation?.male
    } else if (userGender && translation) {
      return translation[userGender === "F" ? "female" : "male"] || translation["male"]
    }

    return translation?.male || translation?.female || (translation as unknown as string)
  }

  /**
   * gender based translation
   * some labels should be localized not only by selected language but also by users gender
   * such labels should contains object with following structure
   * {
   *     "male": "male version of the label",
   *     "female": "female version of the label"
   * }
   * in english always display a male version
   * male version it's a fallback locale for hebrew (in case if female version of label is not specified)
   * */
  function tg(key: string) {
    const resObject = tmsafe<string | string[] | Record<string, any>>(key)
    const resString: string | null = typeof resObject !== "object" ? tsafe(key) : null

    if (resString === key || !resObject) {
      return ""
    } else if (Array.isArray(resObject) && resObject.length) {
      return (resObject as string[]).map((translation) => getFemaleOrMale(translation))
    } else if (typeof resObject === "object" && ("male" in resObject || "female" in resObject)) {
      return getFemaleOrMale(resObject as { female: string; male: string })
    }

    return resString
  }

  /**
   * requires translation path and params
   * returns translation if exists, otherwise returns null
   * */
  function tsafe<T extends string>(path: string, params = {}): T {
    const translated = i18n.t(path, params)

    if (translated === path) return "" as T

    return translated as T
  }

  /**
   *
   * @param {*} obj - gets an object to find the translations key inside ite
   * @param {*} key - the key to search for
   *
   * received object must contain some kind of properties that ends with 'He' or 'En'
   * @returns
   */
  function locale<
    T extends Record<string, any>,
    Key extends T extends never
      ? string
      : StripSuffix<keyof T extends string ? keyof T : string, "He" | "En", true>,
  >(obj: T | undefined, key: Key = "name" as any): T[Key] extends never ? string | null : T[Key] {
    if (!obj) return null!
    const { locale } = i18n

    const heText = obj?.[`${String(key)}He`]
    const enText = obj?.[`${String(key)}En`]
    const baseText = obj?.[key]

    if ([heText, enText, baseText].every(isNullOrUndefined)) {
      return null!
    } else if (locale.value === "en") {
      return enText || baseText
    } else {
      return heText || baseText
    }
  }

  /**
   * Gender based translations with locale support
   * Will act exactly like locale, only it can return male/female strings
   */
  function tgLocale<
    T extends Record<string, any>,
    Key extends T extends never
      ? string
      : StripSuffix<keyof T extends string ? keyof T : string, "HeMale" | "HeFemale" | "En", true>,
  >(obj: T | undefined, key: Key = "name" as any): T[Key] extends never ? string | null : T[Key] {
    if (!obj) return null!
    const { locale } = i18n

    if (locale.value === "en") {
      return obj?.[`${key}En`]
    }
    const user = useUser()
    const isMale = user.user.value?.gender !== "F"
    const genderedTranslation = obj?.[`${key}He` + (isMale ? "Male" : "Female")]

    if (!isNullOrUndefined(genderedTranslation)) {
      return genderedTranslation
    } else if (!isNullOrUndefined(obj?.[`${key}He`])) {
      return obj?.[`${key}He`]
    } else if (!isNullOrUndefined(obj?.[key])) {
      return obj?.[key]
    } else {
      console.warn(`[tgLocale]: Could not translate string to fe/male. source: ${key}`)

      return null!
    }
  }

  /**
   *
   *
   * TODO: cache the result based on the tm object / locale
   *
   *
   */
  function tmsafe<T extends any = never>(
    path: string
  ): T extends never ? string | Record<string, any> | string[] | null : T {
    const { tm } = i18n

    try {
      const translated = tm(path) as any

      if (translated === path) return null as any

      return deeplyResolveMessage(i18n, translated) as any
    } catch (err) {
      console.error(`[tmsafe]: ${err}`)

      return null as any
    }
  }

  function tbp(path: any, params = {}) {
    const { t } = i18n

    const { isMobile } = display
    const countByBreakpoint = isMobile.value ? 0 : 1

    return t(path, params, countByBreakpoint || 0)
  }

  return {
    i18n,
    tbool,
    tg,
    tsafe,
    locale,
    tgLocale,
    tbp,
    tmsafe,
  }
}

interface RtValue {
  loc: any
  type: any
  start: any
  end: any
}

function isRtValue(tm: any): tm is RtValue {
  return ("body" in tm && "type" in tm && "start" in tm && "end" in tm) || ("b" in tm && "t" in tm)
}

function isSafeValue(value: any): boolean {
  return (
    isNullOrUndefined(value) ||
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  )
}

function convertTm(i18n: ReturnType<typeof useI18n>, value: any): string | string[] | Record<string, any> {
  const { rt } = i18n

  if (isSafeValue(value)) {
    return value
  } else if (isRtValue(value)) {
    return rt(value as any)
  } else if (Array.isArray(value)) {
    return value.map((v) => deeplyResolveMessage(i18n, v))
  }

  return deeplyResolveMessage(i18n, value)
}

function deeplyResolveMessage(
  i18n: ReturnType<typeof useI18n>,
  value: Record<string, any> | Record<string, any>[] | unknown
): string | string[] | Record<string, any> {
  const { rt } = i18n

  if (isSafeValue(value)) {
    return value as any
  } else if (Array.isArray(value)) {
    return value.map((v) => deeplyResolveMessage(i18n, v))
  } else if (isRtValue(value)) {
    return rt(value as any)
  }

  return Object.fromEntries(
    Object.entries(value as Record<string, unknown>).map(([key, value]) => {
      return [key, convertTm(i18n, value)]
    })
  )
}
