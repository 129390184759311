import { useReCaptcha } from "vue-recaptcha-v3"

export interface RecaptchaCredentials {
  recaptchaToken: string
}

export type WithRecaptcha<T> = T & RecaptchaCredentials

export const SendGridRecaptchaAction = {
  CONTACT_US: "contactUs",
  B2B_CONTACT: "b2bContact",
} as const

export const AuthRecaptchaAction = {
  LOGIN: "login",
  SIGNUP: "signup",
  VERIFY: "verify",
  FORGOT_PASSWORD: "forgotPassword",
  RESEND_VERIFICATION: "resendVerification",
} as const

export const RecaptchaAction = {
  ...AuthRecaptchaAction,
  ...SendGridRecaptchaAction,
} as const

export type RecaptchaAction = (typeof RecaptchaAction)[keyof typeof RecaptchaAction]

export const withRecaptcha = <T>(
  fn: (data: T, config: KyOptions) => Promise<any>,
  recaptchaToken: string
) => {
  return async (data: T, config: KyOptions = {}) => {
    const finalConfig = {
      ...config,
      headers: {
        ...config.headers,
        "x-recaptcha-response": recaptchaToken,
      },
    }
    return fn(data, finalConfig)
  }
}

export const fetchWithRecaptcha = <T>(action: (data: T, config: KyOptions) => Promise<any>) => {
  return async (data: T, recaptchaToken: string, config: KyOptions = {}) => {
    return withRecaptcha(action, recaptchaToken)(data, config)
  }
}

export function useReCaptchaHandler() {
  const recaptchaInstance = useReCaptcha()

  const executeReCaptcha = async (action: string): Promise<string> => {
    await recaptchaInstance?.recaptchaLoaded()
    const token = await recaptchaInstance?.executeRecaptcha(action)
    if (!token) {
      throw new Error("Failed to execute reCAPTCHA")
    }
    return token
  }

  return {
    executeReCaptcha,
  }
}
