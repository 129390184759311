import { PlanCode, PricingPlanTypes, PricingProductEnum } from "@finq/pricing/types"

export function createLinkFromProduct(product: { screenPath: string }) {
  return isNullOrUndefined(product.screenPath) ? "/" : `/${product.screenPath}`
}

export const ProductsDefaultRoutes: Partial<
  Record<PricingProductEnum, RoutesNamesList | Partial<Record<PlanCode, RoutesNamesList>>>
> = {
  [PricingProductEnum.Stocks]: "stocks-depot",
  [PricingProductEnum.Pensions]: "pension-depot",
  [PricingProductEnum.Funds]: {
    [PricingPlanTypes.PlanCode.DIY]: "funds",
    [PricingPlanTypes.PlanCode.MANAGED]: "profile-investments",
  },
} as const

export function getProductRoute(
  productId: PricingProductEnum,
  planCode?: PlanCode
): RoutesNamesList | undefined {
  const productRoutes = ProductsDefaultRoutes[productId]

  if (typeof productRoutes === "string") return productRoutes

  if (planCode && productRoutes && typeof productRoutes === "object") {
    return productRoutes[planCode]
  }

  return undefined
}

/** Routers and which products can access to at any given time */
export const PathsForProdCodes = {
  "/funds/*": [PricingProductEnum.Funds],
  "/stocks/*": [PricingProductEnum.Stocks],
  "/pension/*": [PricingProductEnum.Pensions],
}

export const tempReplaceLinkIfOwned = (args: {
  isProductOwnedByUser: boolean | undefined
  productPlan: Partial<PricingPlanWithDetails>
}): TypedRouteProps["path"] => {
  const { isProductOwnedByUser: isOwned, productPlan } = args

  const link = createLinkFromProduct({ screenPath: productPlan.screenPath! })

  try {
    const resolvedPath = isKey(ProductsDefaultRoutes, productPlan.productId!)
      ? routerResolve({
          name: getProductRoute(productPlan.productId!, productPlan.code),
        })?.path
      : null

    return isOwned ? stripRoutePathPrefix(resolvedPath!) : link
  } catch (err) {
    console.error(err)

    return link
  }
}

export const SUBSCRIPTION_TYPE: Partial<Record<ProdCodesUnion, string>> = {
  [ProdCodesEnum.Fund]: "",
}
