import routerOptions0 from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/usr/src/apps/base/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}