<template>
  <slot />
</template>

<script lang="ts" setup>
/**
 * Do not delete useI18n composable usage. It is required for the useProvidePricingStore composable to read it from.
 */
useI18n({ useScope: "local" })
</script>

<i18n lang="json">
{
  "en": {
    "products": {
      "PRODUCT_PENSION": {
        "id": "PRODUCT_PENSION",
        "path": "/pension/depot",
        "label": "Pensions",
        "discount": {
          "period": "ANNUAL",
          "percentage": 20
        },
        "plans": {
          "DIY_LIGHT": {
            "title": "Pensions DIY LIGHT",
            "description": "Science-based insights for your pension savings",
            "header_background": "/pricing/card_PRODUCT_PENSION_light.png",
            "badge": "Available only in Hebrew",
            "features": [
              { "title": "Basic access to our pensions depot" },
              {
                "title": "Full access to the ranking of every investment plan of every pension product available in the market for comparison purposes"
              },
              {
                "title": "Full access to returns comparison between investment plans of every pension product"
              },
              { "title": "Basic access to main exposures of each investment plan" }
            ],
            "terms": [
              {
                "id": "agreement",
                "disclaimer": "אני מסכימ/ה לתנאי השימוש ולמדיניות הפרטיות של FINQ. אני מאשר/ת בזאת כי אני מנוי/ה לשירות לשימושי האישי בלבד וכי לא אשתמש במידע המתקבל מהשירות לצורך מתן המלצות השקעה או לניהול השקעות או יעוץ ושיווק פנסיוני עבור אחרים. שירות זה והדירוג המוצרים הפנסיונים המוצג כאן אינם מהווים המלצה לרכישתם, ודירוגם אינו מהווה ייעוץ השקעות ו/או שיווק השקעות ו/או יעוץ פנסיוני ו/או שיווק פנסיוני והוא בגדר מידע בלבד. כל המשתמש במידע זה עושה זאת על אחריותו בלבד. לחברה לא תהיה כל אחריות על פעולה או אי פעולה של הלקוח כתוצאה משימוש במידע זה. מתן השירות אינו מהווה תחליף לייעוץ המתחשב בנתונים ובצרכים המיוחדים"
              }
            ]
          },
          "DIY_PRO": {
            "title": "Pensions DIY PRO",
            "description": "Premium Science-based insights for your pension savings",
            "header_background": "/pricing/card_PRODUCT_PENSION.png",
            "badge": "Available only in Hebrew",
            "features": [
              { "title": "Full access to our pensions depot" },
              {
                "title": "Full access to the ranking of every investment plan of every pension product available in the market for comparison purposes"
              },
              {
                "title": "Full access to returns comparison between investment plans of every pension product"
              },
              { "title": "Full access to main exposures of each investment plan" }
            ],
            "terms": [
              {
                "id": "agreement",
                "disclaimer": "אני מסכימ/ה לתנאי השימוש ולמדיניות הפרטיות של FINQ. אני מאשר/ת בזאת כי אני מנוי/ה לשירות לשימושי האישי בלבד וכי לא אשתמש במידע המתקבל מהשירות לצורך מתן המלצות השקעה או לניהול השקעות או יעוץ ושיווק פנסיוני עבור אחרים. שירות זה והדירוג המוצרים הפנסיונים המוצג כאן אינם מהווים המלצה לרכישתם, ודירוגם אינו מהווה ייעוץ השקעות ו/או שיווק השקעות ו/או יעוץ פנסיוני ו/או שיווק פנסיוני והוא בגדר מידע בלבד. כל המשתמש במידע זה עושה זאת על אחריותו בלבד. לחברה לא תהיה כל אחריות על פעולה או אי פעולה של הלקוח כתוצאה משימוש במידע זה. מתן השירות אינו מהווה תחליף לייעוץ המתחשב בנתונים ובצרכים המיוחדים"
              },
              {
                "id": "personal_use",
                "disclaimer": "אני מצהיר/ה שהשימוש שאעשה במידע במסגרת המנוי יהיה לשימוש אישי בלבד, ולא לשימוש מקצועי או מסחרי או שימוש כסוכן. כל הפרה של תנאי שימוש זה תהווה עילה לחיובך בסכום של 100,000 ש\"ח לחודש."
              }
            ]
          },
          "MANAGED": {
            "title": "Pensions MANAGED",
            "description": "A to Z management of your pension savings",
            "header_background": "/pricing/card_PRODUCT_PENSION_managed.png",
            "badge": "Available only in Hebrew",
            "features": [
              { "title": "Access to our pensions depot" },
              {
                "title": "Access to the ranking of every investment plan of every pension product available in the market for comparison purposes"
              },
              { "title": "Access to returns comparison between investment plans of every pension product" },
              {
                "title": "Connection to the pension clearing house (מסלקה) and Har Habituach and downloading your personal data"
              },
              { "title": "Personalized recommendations for all your pension products" },
              { "title": "End-to-end digital management of all your pension products" },
              {
                "title": "Monitoring all pension products on the market, ongoing updated recommendations, and implementation upon your approval"
              }
            ]
          }
        }
      },
      "PRODUCT_STOCK": {
        "id": "PRODUCT_STOCK",
        "path": "/stocks",
        "label": "Stocks",
        "discount": {
          "period": "ANNUAL",
          "percentage": 20
        },
        "plans": {
          "DIY": {
            "title": "S&P 500 Stocks DIY",
            "description": "Science-based insights for smarter stocks investing",
            "header_background": "/pricing/card_PRODUCT_STOCK.png",
            "features": [
              {
                "title": "STOCKS DEPOT",
                "description": "Daily-updated ranking of all S&P 500 stocks"
              },
              {
                "title": "FINQFIRST portfolio",
                "description": "Our top 10 stocks to buy (long strategy)"
              },
              {
                "title": "FINQLAST portfolio",
                "description": "Our top 10 stocks to short-sell (short strategy)"
              },
              {
                "title": "FINQNEUTRAL portfolio",
                "description": "Our top 10 stocks to buy + 10 to short-sell (dual strategy)"
              }
            ],
            "terms": [
              {
                "id": "agreement",
                "disclaimer": "I hereby confirm that I subscribe to the service for my personal use only and that I will not use the information obtained from the service for the purpose of providing investment recommendations or for managing investments for others. This service and the rating of the shares presented here do not constitute a recommendation for their purchase. The rating provided does not imply investment advice and/or investment marketing; it is for informational purposes only. Anyone using this information does so at their own risk. The company will have no responsibility for the customer's action or inaction as a result of using this information. The provision of the service is not a substitute for advice that takes into account the data and the special needs of each person. The company has no personal interest in the rated shares."
              }
            ]
          },
          "MANAGED": {
            "title": "S&P 500 Stocks MANAGED",
            "banner_title": "Stocks MANAGED",
            "description": "A to Z science-based stocks portfolio management",
            "header_background": "/pricing/card_PRODUCT_STOCK_managed.png",
            "features": [
              {
                "title": "STOCKS DEPOT",
                "description": "Daily-updated ranking of all S&P 500 stocks"
              },
              {
                "title": "FINQFIRST portfolio",
                "description": "Our top 10 stocks to buy (long strategy)"
              },
              {
                "title": "FINQLAST portfolio",
                "description": "Our top 10 stocks to short-sell (short strategy)"
              },
              {
                "title": "FINQNEUTRAL portfolio",
                "description": "Our top 10 stocks to buy + 10 to short-sell (dual strategy)"
              },
              { "title": "Hassle-free execution of any investment strategy" }
            ]
          }
        }
      },
      "PRODUCT_FUND": {
        "id": "PRODUCT_FUND",
        "path": "/funds",
        "label": "Mutual Funds",
        "discount": {
          "period": "ANNUAL",
          "percentage": 20
        },
        "plans": {
          "DIY": {
            "title": "Mutual funds PORTAL",
            "description": "A comprehensive access to unique data of all the Israeli mutual funds",
            "header_background": "/pricing/card_PRODUCT_FUND.png",
            "screenPath": "funds",
            "features": [
              { "title": "Access to all Israeli-managed funds, sorted by category, fund manager, or type" },
              {
                "title": "Exposure to comprehensive data containing asset allocation, historical returns, and more, all in one place"
              }
            ]
          },
          "MANAGED": {
            "title": "Investment portfolio MANAGED",
            "description": "A to Z science-based mutual funds and ETFs portfolio management",
            "header_background": "/pricing/card_PRODUCT_FUND_managed.png",
            "bill_period": "Management fee per year. Billed quarterly.",
            "screenPath": "kyc/INV",
            "features": [
              { "title": "Access to all Israeli-managed funds, sorted by category, fund manager, or type" },
              {
                "title": "Exposure to comprehensive data containing asset allocation, historical returns, and more, all in one place"
              },
              {
                "title": "An investment portfolio tailored to your needs based on a questionnaire for evaluating your individual risk level"
              },
              {
                "title": "Fully managed AI-based investment portfolio of mutual funds or ETFs according to our preference"
              }
            ]
          }
        }
      }
    }
  },
  "he": {
    "products": {
      "PRODUCT_PENSION": {
        "id": "PRODUCT_PENSION",
        "path": "/pension/depot",
        "label": "פנסיות",
        "discount": {
          "period": "ANNUAL",
          "percentage": 20
        },
        "plans": {
          "DIY_LIGHT": {
            "title": "פנסיות DIY LIGHT",
            "description": "תובנות מבוססות מדע לחסכון הפנסיוני שלך",
            "header_background": "/pricing/card_PRODUCT_PENSION_light.png",
            "features": [
              { "title": "גישה לדיפו חסכונות פנסיונים" },
              { "title": "גישה מלאה לדירוג מסלולי ההשקעה של כל המוצרים הפנסיונים הזמינים בשוק לצרכי השוואה" },
              { "title": "גישה מלאה להשוואת תשואה במסלולי ההשקעה של כל אחד מהמוצרים הפנסיונים" },
              { "title": "גישה בסיסית לחשיפות העיקריות של כל מסלול השקעה" }
            ],
            "terms": [
              {
                "id": "agreement",
                "disclaimer": "אני מסכימ/ה לתנאי השימוש ולמדיניות הפרטיות של FINQ. אני מאשר/ת בזאת כי אני מנוי/ה לשירות לשימושי האישי בלבד וכי לא אשתמש במידע המתקבל מהשירות לצורך מתן המלצות השקעה או לניהול השקעות או יעוץ ושיווק פנסיוני עבור אחרים. שירות זה והדירוג המוצרים הפנסיונים המוצג כאן אינם מהווים המלצה לרכישתם, ודירוגם אינו מהווה ייעוץ השקעות ו/או שיווק השקעות ו/או יעוץ פנסיוני ו/או שיווק פנסיוני והוא בגדר מידע בלבד. כל המשתמש במידע זה עושה זאת על אחריותו בלבד. לחברה לא תהיה כל אחריות על פעולה או אי פעולה של הלקוח כתוצאה משימוש במידע זה. מתן השירות אינו מהווה תחליף לייעוץ המתחשב בנתונים ובצרכים המיוחדים"
              }
            ]
          },
          "DIY_PRO": {
            "title": "פנסיות DIY PRO",
            "description": "תובנות פרימיום מבוססות מדע לחסכון הפנסיוני שלך",
            "header_background": "/pricing/card_PRODUCT_PENSION.png",
            "features": [
              { "title": "גישה לדיפו חסכונות פנסיונים" },
              { "title": "גישה מלאה לדירוג מסלולי ההשקעה של כל המוצרים הפנסיונים הזמינים בשוק לצרכי השוואה" },
              { "title": "גישה מלאה להשוואת תשואה במסלולי ההשקעה של כל אחד מהמוצרים הפנסיונים" },
              { "title": "גישה מלאה לפרטי כל מסלול השקעה עד לרמת הנכסים הבודדים והרכבי החשיפה" }
            ],
            "terms": [
              {
                "id": "agreement",
                "disclaimer": "אני מסכימ/ה לתנאי השימוש ולמדיניות הפרטיות של FINQ. אני מאשר/ת בזאת כי אני מנוי/ה לשירות לשימושי האישי בלבד וכי לא אשתמש במידע המתקבל מהשירות לצורך מתן המלצות השקעה או לניהול השקעות או יעוץ ושיווק פנסיוני עבור אחרים. שירות זה והדירוג המוצרים הפנסיונים המוצג כאן אינם מהווים המלצה לרכישתם, ודירוגם אינו מהווה ייעוץ השקעות ו/או שיווק השקעות ו/או יעוץ פנסיוני ו/או שיווק פנסיוני והוא בגדר מידע בלבד. כל המשתמש במידע זה עושה זאת על אחריותו בלבד. לחברה לא תהיה כל אחריות על פעולה או אי פעולה של הלקוח כתוצאה משימוש במידע זה. מתן השירות אינו מהווה תחליף לייעוץ המתחשב בנתונים ובצרכים המיוחדים"
              },
              {
                "id": "personal_use",
                "disclaimer": "אני מצהיר/ה שהשימוש שאעשה במידע במסגרת המנוי יהיה לשימוש אישי בלבד, ולא לשימוש מקצועי או מסחרי או שימוש כסוכן. כל הפרה של תנאי שימוש זה תהווה עילה לחיובך בסכום של 100,000 ש\"ח לחודש."
              }
            ]
          },
          "MANAGED": {
            "title": "ניהול פנסיוני מלא",
            "description": "ניהול מקצה לקצה של החסכון הפנסיוני שלך",
            "header_background": "/pricing/card_PRODUCT_PENSION_managed.png",
            "features": [
              { "title": "גישה מלאה לדירוג מסלולי ההשקעה של כל המוצרים הפנסיונים הזמינים בשוק לצרכי השוואה" },
              { "title": "גישה מלאה להשוואת תשואה במסלולי ההשקעה של כל אחד מהמוצרים הפנסיונים" },
              { "title": "גישה מלאה לפרטי כל מסלול השקעה עד לרמת הנכסים הבודדים והרכבי החשיפה" },
              { "title": "חיבור למסלקה הפנסיונית והר הביטוח והורדת הנתונים האישיים" },
              { "title": "המלצות מותאמות אישית לכל המוצרים הפנסיונים שברשותך" },
              { "title": "ניהול דיגיטלי מקצה לקצה של כל המוצרים הפנסיונים שברשותך" },
              { "title": "ניטור כל המוצרים הפנסיונים בשוק, עדכון המלצות בשוטף וביצוע לאחר אישורך" }
            ]
          }
        }
      },
      "PRODUCT_STOCK": {
        "id": "PRODUCT_STOCK",
        "path": "/stocks",
        "label": "מניות",
        "discount": {
          "period": "ANNUAL",
          "percentage": 20
        },
        "plans": {
          "DIY": {
            "title": "מניות S&P 500 DIY",
            "description": "תובנות מבוססות מדע להשקעה חכמה במניות",
            "header_background": "/pricing/card_PRODUCT_STOCK.png",
            "features": [
              {
                "title": "דיפו מניות",
                "description": "גישה מלאה לדירוג יומי של כל מניות ה-S&P 500"
              },
              {
                "title": "תיק מניות FINQFIRST",
                "description": "עשרת המניות המומלצות לקניה (אסטרטגיית long)"
              },
              {
                "title": "תיק מניות FINQLAST",
                "description": "עשרת המניות המומלצות למכירה בחסר (אסטרטגיית short)"
              },
              {
                "title": "תיק מניות FINQNEUTRAL",
                "description": "עשרת המניות המומלצות לקניה + עשרת המניות למכירה בחסר (אסטרטגיה משולבת)"
              }
            ],
            "terms": [
              {
                "id": "agreement",
                "disclaimer": "אני מאשר/ת בזאת כי אני מנוי/ה לשירות לשימושי האישי בלבד וכי לא אשתמש במידע המתקבל מהשירות לצורך מתן המלצות השקעה או לניהול השקעות עבור אחרים. שירות זה ודירוג המניות המוצגות כאן אינם מהווים המלצה לרכישתן ודירוגן אינו מהווה ייעוץ השקעות ו/או שיווק השקעות והוא בגדר מידע בלבד. כל המשתמש/ת במידע זה עושה זאת על אחריותו/ה בלבד. לחברה לא תהיה כל אחריות על פעולה או אי פעולה של הלקוח/ה כתוצאה משימוש במידע זה. מתן השירות אינו מהווה תחליף לייעוץ המתחשב בנתונים ובצרכים המיוחדים של כל אדם. לחברה אין עניין אישי במניות המדורגות."
              }
            ]
          },
          "MANAGED": {
            "title": "ניהול תיק מניות 500 S&P",
            "banner_title": "ניהול תיק מניות",
            "description": "ניהול מקצה לקצה של תיקי מניות מבוססי מדע",
            "header_background": "/pricing/card_PRODUCT_STOCK_managed.png",
            "features": [
              {
                "title": "דיפו מניות",
                "description": "גישה מלאה לדירוג יומי של כל מניות ה-S&P 500"
              },
              {
                "title": "תיק מניות FINQFIRST",
                "description": "עשרת המניות המומלצות לקניה (אסטרטגיית long)"
              },
              {
                "title": "תיק מניות FINQLAST",
                "description": "עשרת המניות המומלצות למכירה בחסר (אסטרטגיית short)"
              },
              {
                "title": "תיק מניות FINQNEUTRAL",
                "description": "עשרת המניות המומלצות לקניה + עשרת המניות למכירה בחסר (אסטרטגיה משולבת)"
              },
              { "title": "מימוש קל וללא טרחה של כל אסטרטגיית השקעה" }
            ]
          }
        }
      },
      "PRODUCT_FUND": {
        "id": "PRODUCT_FUND",
        "path": "/funds",
        "label": "קרנות נאמנות",
        "discount": {
          "period": "ANNUAL",
          "percentage": 20
        },
        "plans": {
          "DIY": {
            "title": "פורטל קרנות נאמנות",
            "description": "גישה מלאה למידע ייחודי מסוגו על כל קרנות הנאמנות בישראל",
            "header_background": "/pricing/card_PRODUCT_FUND.png",
            "screenPath": "funds",
            "features": [
              { "title": "גישה לכל קרנות הנאמנות בישראל לפי קטגוריה, מנהל קרן או סוג קרן" },
              { "title": "חשיפה לנתונים מקיפים של התפלגות נכסים, תשואות היסטוריות ועוד, והכל במקום אחד" }
            ]
          },
          "MANAGED": {
            "title": "תיק השקעות מנוהל",
            "description": "ניהול מלא מבוסס AI של תיק השקעות מותאם אישית הכולל קרנות נאמנות או תעודות סל",
            "header_background": "/pricing/card_PRODUCT_FUND_managed.png",
            "bill_period": "דמי ניהול שנתיים מההשקעה. חיוב רבעוני.",
            "screenPath": "kyc/INV",
            "features": [
              { "title": "גישה לכל קרנות הנאמנות בישראל לפי קטגוריה, מנהל קרן או סוג קרן" },
              { "title": "חשיפה לנתונים מקיפים של התפלגות נכסים, תשואות היסטוריות ועוד" },
              { "title": "תיק השקעות מותאם לצרכים שלך מבוסס שאלון להערכת רמת הסיכון האישית שלך" },
              { "title": "תיק השקעות מבוסס AI בניהול מלא של קרנות נאמנות או תעודות סל בהעדפה אישית" }
            ]
          }
        }
      }
    }
  }
}
</i18n>
