<script setup lang="ts">
import { DialogRoot, type DialogRootEmits, type DialogRootProps, useForwardPropsEmits } from "radix-vue"

import { UiDialogContentClasses, UiDialogContentProps } from "@finq/ui/components/ui/Dialog/DialogContent.vue"
import type { UiClassesToAttributes } from "@finq/ui/types/common"

export interface UiDialogBasicClasses extends UiDialogContentClasses {
  root?: ClassValue
  overlay?: ClassValue
  panel?: ClassValue
}

export interface UiDialogBasicProps extends DialogRootProps, UiDialogContentProps {
  class?: ClassValue
  classes?: UiDialogBasicClasses
  attributes?: UiClassesToAttributes<UiDialogBasicClasses, false>

  trapFocus?: boolean
  persistent?: boolean
  scrollable?: boolean
}

const props = withDefaults(defineProps<UiDialogBasicProps>(), { animated: true })
const emits = defineEmits<DialogRootEmits>()
const delegatedProps = computed(() => {
  const dialogProps: DialogRootProps = {
    defaultOpen: props.defaultOpen,
    open: props.open,
    modal: props.modal,
  }
  return dialogProps
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

const slots = useSlots()
const hasTriggerSlot = computed(() => !!slots.trigger)

const contentBinds = computed(() => ({
  classes: props.classes,
  backdrop: props.backdrop,
  trapFocus: props.trapFocus,
  hideCloseButton: props.hideCloseButton,
  animated: props.animated,
  persistent: props.persistent,
  disableOutsidePointerEvents: props.disableOutsidePointerEvents,
  onInteractOutside: (e: Event) => props.persistent && e.preventDefault(),
}))

const Content = computed(() => {
  return props.scrollable ? resolveComponent("UiDialogScrollContent") : resolveComponent("UiDialogContent")
})
</script>

<template>
  <UiDialog v-bind="forwarded">
    <UiDialogTrigger v-if="hasTriggerSlot" as-child>
      <slot name="trigger" />
    </UiDialogTrigger>

    <ClientOnly>
      <slot v-bind="contentBinds" name="content">
        <Content v-bind="contentBinds"><slot /></Content>
      </slot>
    </ClientOnly>
  </UiDialog>
</template>
