import { VueReCaptcha } from "vue-recaptcha-v3"

export default defineNuxtPlugin({
  name: "recaptcha",
  parallel: true,
  setup(nuxtApp) {
    const config = useRuntimeConfig()

    nuxtApp.vueApp.use(VueReCaptcha, {
      siteKey: config.public.NUXT_PUBLIC_RECAPTCHA_API_KEY,
      loaderOptions: {
        autoHideBadge: true,
      },
    })
  },
})
