<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogPortal,
  useForwardPropsEmits,
} from "radix-vue"

import { UiDialogOverlayProps } from "@finq/ui/components/ui/Dialog/DialogOverlay.vue"

export interface UiDialogContentClasses {
  root?: ClassValue
  overlay?: ClassValue
  panel?: ClassValue
  close?: ClassValue
}

export interface UiDialogContentProps extends DialogContentProps {
  class?: ClassValue
  classes?: UiDialogContentClasses
  backdrop?: UiDialogOverlayProps["backdrop"]
  hideCloseButton?: boolean
  animated?: boolean
  persistent?: boolean
}

const props = withDefaults(defineProps<UiDialogContentProps>(), { animated: true })
const emits = defineEmits<DialogContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, classes, backdrop, hideCloseButton, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <DialogPortal>
    <UiDialogOverlay :class="cn('dialog-backdrop z-60', classes?.overlay)" :backdrop="backdrop" />

    <DialogContent
      v-bind="forwarded"
      :aria-describedby="undefined"
      :class="
        cn(
          'bg-background z-60 fixed left-1/2 top-1/2 flex max-h-screen w-full max-w-lg -translate-x-1/2 -translate-y-1/2 flex-col items-stretch rounded-lg p-4 shadow-lg !duration-300 ease-in-out md:bottom-0 md:left-0 md:top-[unset] md:translate-x-[unset] md:translate-y-[unset] md:rounded-b-none',
          animated &&
            'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 min-md:data-[state=closed]:zoom-out-95 min-md:data-[state=open]:zoom-in-95 min-md:data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] min-md:data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] md:data-[state=open]:slide-in-from-bottom-full md:data-[state=closed]:slide-out-to-bottom-full',
          props.class,
          props.classes?.panel
        )
      "
    >
      <slot />

      <DialogClose v-if="!hideCloseButton" as-child>
        <UiButton
          v-if="!hideCloseButton"
          :class="cn('absolute end-5 top-5 z-[60] md:end-4 md:top-4', classes?.close)"
          variant="icon"
        >
          <LucideX :size="20" />
          <span class="sr-only">Close</span>
        </UiButton>
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>
