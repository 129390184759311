import revive_payload_client_PWozJi93jB from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_rSKN2NHeKc from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ID7zjBhksv from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_P8blaaRUKZ from "/usr/src/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.21.0_vite@5.4.1_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_gwmJyJEFaE from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_EY4dz7vKv8 from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jHZFsad70v from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_q11GlRVNPi from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qmYZY2qInU from "/usr/src/node_modules/.pnpm/@pinia+nuxt@0.5.3_rollup@4.21.0_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/apps/finq-finance/.nuxt/components.plugin.mjs";
import prefetch_client_vMrKkfHOR4 from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_tMjxCFRIhX from "/usr/src/node_modules/.pnpm/@vueuse+motion@2.2.3_vue@3.4.38/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_ziYcKwnJdq from "/usr/src/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_e0zMeJCHEY from "/usr/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_VqOTdjIRDp from "/usr/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import eventBus_client_6G4UJ8ZbRL from "/usr/src/apps/base/src/plugins/eventBus.client.ts";
import formatHtml_g8oRQLd2uu from "/usr/src/apps/base/src/plugins/formatHtml.ts";
import gtm_faEZkqnVWN from "/usr/src/apps/base/src/plugins/gtm.ts";
import intersect_ellX5rVgT3 from "/usr/src/apps/base/src/plugins/intersect.ts";
import localStorage_MHY01ePQwD from "/usr/src/apps/base/src/plugins/localStorage.ts";
import recaptcha_wOTe5uVF3s from "/usr/src/apps/base/src/plugins/recaptcha.ts";
import vue_query_FsGdL7i3dD from "/usr/src/apps/base/src/plugins/vue-query.ts";
export default [
  revive_payload_client_PWozJi93jB,
  unhead_rSKN2NHeKc,
  router_ID7zjBhksv,
  _0_siteConfig_P8blaaRUKZ,
  payload_client_gwmJyJEFaE,
  navigation_repaint_client_EY4dz7vKv8,
  check_outdated_build_client_jHZFsad70v,
  chunk_reload_client_q11GlRVNPi,
  plugin_vue3_qmYZY2qInU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vMrKkfHOR4,
  motion_tMjxCFRIhX,
  plugin_ziYcKwnJdq,
  switch_locale_path_ssr_e0zMeJCHEY,
  i18n_VqOTdjIRDp,
  eventBus_client_6G4UJ8ZbRL,
  formatHtml_g8oRQLd2uu,
  gtm_faEZkqnVWN,
  intersect_ellX5rVgT3,
  localStorage_MHY01ePQwD,
  recaptcha_wOTe5uVF3s,
  vue_query_FsGdL7i3dD
]